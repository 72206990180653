import { gql } from '@apollo/client'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { Button, Input } from '@nextui-org/react'
import { Back, Logo, Navigation } from 'components'
import { ErrorAlert } from 'components/alert'
import { Route, query } from 'lib'
import { LoginPageMeQuery } from 'lib/generated'
import { GetServerSidePropsContext, NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

const meQuery = gql`
  query LoginPageMe {
    me {
      id
    }
  }
`

type FormData = {
  email: string
}

type AwaitProps = {
  email: string
  token: string
  redirectTo: string
  onUndo: () => void
}

const AwaitConfirmation = ({ email, token, redirectTo, onUndo }: AwaitProps) => {
  const router = useRouter()
  const url = `${process.env.NEXT_PUBLIC_API_ROOT}/verify?email=${email}&token=${token}&redirect_to=${redirectTo}`
  const fetcher = (url: string) => fetch(url, { credentials: 'include' }).then((res) => res.json())
  const { data, error } = useSWR(url, fetcher, { refreshInterval: 3000 })

  useEffect(() => {
    if (!data) {
      return
    }
    router.push(redirectTo)
  }, [redirectTo, router, data])

  return (
    <div className="flex max-w-md flex-col gap-4">
      <h1 className="text-center text-4xl">Waiting for Confirmation</h1>
      <h2 className="text-lg">Please do not close this window until opening the email link.</h2>
      <p>
        We just sent an email to <span className="font-bold">{email}</span>{' '}
        <span className="break">
          <button className="underline" onClick={onUndo}>
            (undo)
          </button>
        </span>
        . Please click the link in the email to log in.
      </p>
    </div>
  )
}

type LoginFormProps = {
  onSuccess: (email: string, token: string, redirectTo: string) => void
}

/**
 * Login form owns the login process up until the user successfully submits a
 * valid email. Once we send the magic link we switch awaiting for confirmation.
 */
const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const [error, setError] = useState('')
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async ({ email }: FormData) => {
    try {
      const queryParams = new URLSearchParams(window.location.search)
      const redirectTo = queryParams.get('redirect_to') || window.location.origin
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_ROOT}/login?redirect_to=${redirectTo}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res.status >= 400) {
        setError((await res.json()) || `Sorry, that didn't work.`)
        return
      }
      const { token } = await res.json()
      onSuccess(email, token, redirectTo)
    } catch (err: any) {
      setError(err?.message)
    }
  }

  return (
    <>
      <h1 className="text-4xl">
        Log in to <Logo />
      </h1>
      {error && <ErrorAlert>{error}</ErrorAlert>}
      <form className="flex w-full flex-col items-center gap-8 md:w-[300px]" onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('email')}
          autoFocus
          isRequired
          endContent={<EnvelopeIcon className="h-5 w-5" />}
          label="Email"
          type="email"
          placeholder="Enter your email"
          variant="bordered"
        />
        <Button className="w-full" color="primary" type="submit">
          <span className="w-full text-center">Continue</span>
        </Button>
      </form>
    </>
  )
}

type AuthResponse = {
  email: string
  token: string
  redirectTo: string
}

const Login: NextPage = () => {
  const [auth, setAuth] = useState<AuthResponse>({
    email: '',
    token: '',
    redirectTo: '',
  })
  const [sent, setSent] = useState(false)

  const onSend = (email: string, token: string, redirectTo: string) => {
    setAuth({
      email,
      token,
      redirectTo,
    })
    setSent(true)
  }

  const undo = () => setSent(false)

  return (
    <>
      <Navigation left={<Back />} />
      <div className="mt-20 flex flex-col items-center gap-12 px-4 md:mt-64 md:px-0">
        {sent ? (
          <AwaitConfirmation email={auth.email} token={auth.token} redirectTo={auth.redirectTo} onUndo={undo} />
        ) : (
          <LoginForm onSuccess={onSend} />
        )}
      </div>
    </>
  )
}

export default Login

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  try {
    // Check if the user is authenticated, if so, send them to the home page
    await query<LoginPageMeQuery>(ctx, meQuery)
    return {
      redirect: {
        permanent: false,
        destination: Route.Home,
      },
    }
  } catch (err) {
    return {
      props: {},
    }
  }
}
